export default (vue, error, errorCallback) => {
    if (!vue._.has(error, 'response.status')) {
        vue.$store.commit('setGlobalError', vue.$t('connection_error_please_try_again'));
    } else if(error.response.status === 401) {
        vue.$logout(vue);
    } else if(error.response.status === 403 && vue._.has(error, 'response.data.message')) {
        vue.$store.commit('setGlobalAuthorizationError', error.response.data.message);
    } else if (vue._.has(error, 'response.data') && error.response.status === vue.$config.defaultErrorStatusCode && vue._.isFunction(errorCallback)) {
        errorCallback(error.response.data);
    } else {
        vue.$store.commit('setGlobalError', vue.$t('something_went_wrong'));
    }
}
