import axios from 'axios';
import store from '../store/store'
import appConfig from '../config';

axios.interceptors.request.use(function (config) {
    // console.log(process.env);
    store.commit('clearGlobalError');
    if (store.state.forceGlobalLoadingIsEnabled) {
        store.commit('enableGlobalLoading');
    }
    config.headers['Accept-Language'] = store.state.languageCode;
    if (appConfig.phpXdebug) {
        config.url = `${config.url}${config.url.includes('?') ? '&' : '?'}XDEBUG_SESSION=gado` // todo add to config DEBUG
    }

    let apiUrl = 'http://192.168.43.96/api';
    if (window.location.href.includes('almashreq.fatorty.online')) {
        apiUrl = 'http://almashreq_api.fatorty.online/api';
    } else if (window.location.href.includes('alhammad.fatorty.online')) {
        apiUrl = 'http://alhammad_api.fatorty.online/api';
    }

    if (appConfig.isProduction) {
        if (appConfig.profile.name === 'profile_almashreq') {
            apiUrl = 'http://almashreq_api.fatorty.online/api';
        } else if (appConfig.profile.name === 'profile_alhammad') {
            apiUrl = 'http://alhammad_api.fatorty.online/api';
        }
    }

    config.url = config.url.replace('http://192.168.43.96/api', apiUrl);
    return config;
}, function (error) {
    return Promise.reject(error)
});

axios.interceptors.response.use(function (response) {
    store.commit('disableGlobalLoading');
    return response;
}, function (error) {
    store.commit('disableGlobalLoading');
    return Promise.reject(error);
});

export default axios;
